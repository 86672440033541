import React from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'sana/utils';
import styles from '../FlexiTile.module.scss';
import { isUndefined, defaultFontColor } from '../ImageBlockUtilities';

const Title = ({ tileConfigData }) => {
    const { titleColor, titleHighlightColor } = { ...tileConfigData };
    const fontColor = { color: titleColor ? titleColor : 'inherit' };
    const highlightColor = titleHighlightColor ? titleHighlightColor : defaultFontColor;
    const titleStyles = tileConfigData.highlightTitle ?
        {
            backgroundColor: highlightColor,
            WebkitBoxShadow: `10px 0 0 ${highlightColor}, -10px 0 0 ${highlightColor}`,
            MozBoxShadow: `10px 0 0 ${highlightColor}, -10px 0 0 ${highlightColor}`,
            ...fontColor,
        } :
        {
            backgroundColor: 'unset',
            ...fontColor,
        };

    return (
        <div className={styles.flexiTileHeader}>
            <div className={titleColor ? styles.textWrapper : styles.textMargin} style={titleStyles}>
                {renderHTML(!isUndefined(tileConfigData.title) ? tileConfigData.title : '')}
            </div>
        </div>
    );
};

Title.propTypes = {
    tileConfigData: PropTypes.shape({
        titleColor: PropTypes.string,
        title: PropTypes.string,
        highlightTitle: PropTypes.bool,
        titleHighlightColor: PropTypes.string,
    }),
};

export default React.memo(Title);